@media (max-width: 576px) {
  .pagination {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }
}

.theme--dark {
  .pagination {
    a  {
      background: #303238;
    }
    .active a {
      background: #4c5a68;
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 3rem;
  min-width: 100%;
  list-style: none;

  .previous a, .next a {
    background: transparent;
    text-decoration: underline;
  }

  .next a {
    margin-right: 0;
    padding-right: 0;
  }

  a {
    min-width: 30px;
    height: 30px;
    border-radius: .16rem;
    cursor: pointer;
    background: #e6ecef;
    text-align: center;
    text-decoration: none;
    margin: 0 7px 0 0;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 400;
  }

  .active a {
    background: #d8eafc;
  }
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
  color: var(--color);
}


.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
  color: var(--color);
  text-decoration: none;
  opacity: 1;
}
