@use "sass:math";
@import "../../all-modules.global";

.popup {
  position: absolute;
  width: 150px;
  border: 1px solid var(--icon);
  @include shadow;
  border-radius: 4px;
  right: 0;
  background: var(--background);
  top: 44px;
  z-index: 1;
  overflow: hidden;
  box-sizing: content-box;
  line-height: 18px;
  font-size: 16px;

  $popup-item-height: 24px;
  $popup-item-icon-width: 18px;
  &__item {
    padding: 11px 16px 11px 16px + $popup-item-icon-width + 12px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    background-color: var(--background-2);
    &::before {
      content: '';
      width: $popup-item-icon-width;
      height: $popup-item-icon-width;
      position: absolute;
      left: 16px;
      top: 8px + math.div($popup-item-height - $popup-item-icon-width, 2);
      background-color: var(--color);
    }
    &:hover {
      background-color: var(--icon);
    }
  }

  &__item:first-child {
    padding-top: 19px;
    &::before {
      top: 16px + math.div($popup-item-height - $popup-item-icon-width, 2);
    }
  }

  &__item:last-child {
    padding-bottom: 19px;
  }

  &__item_type_link {
    padding: 0;
    &::before {
      top: 16px + math.div($popup-item-height - $popup-item-icon-width, 2);
    }
    a {
      padding: 11px 16px 11px 16px + $popup-item-icon-width + 12px;
      text-decoration: none;
      display: block;
      line-height: 18px;
      font-size: 16px;
    }
    a:hover {
      text-decoration: none;
    }
  }

  &__item_type_link:first-child {
    padding: 0;

    a {
      padding-top: 19px;
    }
  }
}