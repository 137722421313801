@import "../../all-modules.global";

.map-column-page-container {
  display: flex;
  $width: 562px;
  margin: 64px - 48px 0 0 0; // 48px by default

  &__container {
    min-width: $width + 64px;
    max-width: $width + 64px;
    padding: 0 64px 0 10px;
    margin-left: -10px;
    @include font-size(sm);
  }

  // width when map = 300px
  @media (max-width: 1044px) {
    &__container {
      min-width: initial;
      width: 100%;
      max-width: initial;
      padding: 0;
      margin: 0;
      overflow-y: initial;
      max-height: initial;
    }
  }
}