@import "../../all-modules.global";


.instruction {
  $min-card-width: 126px;
  $image-width: 76px;
  $image-small-width: 52px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    counter-reset: number;
    margin-top: 14px + 10px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    counter-reset: number;
    margin-top: 24px;
  }

  :global(.theme--dark) &__image {
    background-color: rgba(var(--brand-rgb), 0.2);
  }

  &__card {
    flex: 1 0 0;
    min-width: $min-card-width;
    display: flex;
    gap: 16px;
    max-width: 134px;
  }

  &__list_copy &__card:last-child  {
    max-width: 194px;
  }

  &__list_copy &__card  {
    max-width: 170px;
  }

  &__list_scan &__card {
    max-width: 170px;
    width: 170px;
  }

  &_orientation_row &__card {
    flex-direction: column;
  }

  & &__card-title {
    line-height: 24px;
    margin: 0 0 4px 0;
  }

  &__image {
    position: relative;
    width: $image-width;
    height: $image-width;
    border-radius: 16px;
    background-color: rgba(var(--brand-rgb), 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: number;
    min-width: $image-width;

    &::before {
      width: 32px;
      height: 32px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: var(--color);
      content: '';
    }

    &_type_flag::before {
      mask: url('/static/img/instruction/flag.svg');
    }

    &_type_card::before {
      mask: url('/static/img/instruction/card.svg');
    }

    &_type_map::before {
      mask: url('/static/img/instruction/map.svg');
    }

    &_type_scanner::before {
      mask: url('/static/img/instruction/scanner.svg');
    }

    &_type_attach-doc::before {
      mask: url('/static/img/instruction/attach-doc.svg');
    }

    &::after {
      content: counter(number);
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background-color: var(--brand);
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      font-weight: 500;
      @include font-size(l);
      position: absolute;
      left: -10px;
      top: -10px;
    }

    img {
      width: 36px;
      height: 36px;
    }
  }

  &__card p {
    color: var(--color-2);
  }

  &_orientation_column &__card {
      flex-direction: row;
      min-width: 100%;
  }

  &_orientation_column &__list {
      gap: 8px + 16px;
      margin-top: 14px + 12px; // (10px - 8px) + 10px
  }

  &_orientation_column &__image {
    width: $image-small-width;
    height: $image-small-width;
    min-width: $image-small-width;

    &:after {
      width: 24px;
      height: 24px;
      font-size: var(--font-size-m);
      line-height: 24px;
      left: -8px;
      top: -8px;
    }

    &:before {
      width: 24px;
      height: 24px;
    }
  }
}