@import "../../all-modules.global";

.document-error-modal {
  &__description {
    margin-bottom: 36px;
    li {
      list-style: decimal inside;
      margin: 0 0 4px 0;
    }
    li::marker {
      font-weight: 500;
    }
  }

  &__title {
    margin: 0 0 8px 0;
  }

  &__content {
    width: 500px !important; //wrong order of styles with modal__content
  }
}