.map-filter {
  &__heading {
    margin: 0 0 8px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}