@import "../../all-modules.global";

.code-input {
  display: flex;
  gap: 8px;
  justify-content: center;

  input {
    width: 59px; // 4 inputs + gaps = 260px
    height: 80px;
    @include font-size(xl);
    text-align: center;
    border: none;
    border-radius: 4px;
    background: var(--icon);
    color: var(--color);
    box-shadow:  none;
    transition: .3s linear;
  }

  input:focus, input:hover {
    background: var(--icon-hover);
    outline: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}