.document-loading {
  position: relative;
  height: 100%;

  :global(.st0) {
    fill: var(--light-blue);
  }
  :global(.st1) {
    fill: var(--light-blue-hover);
  }

  :global(.theme--dark) & {
    &__line {
      background: #5286b5;
      &::after {
        background: #2c4052;
      }
    }
  }

  svg {
    height: 100%;
    width: 100%;
  }

  &__line {
    &::before {
      content: '';
      display: block;
      padding-top: 15%;
    }
    &::after {
      content: '';
      display: block;
      height: 100%;
      left: -100%;
      background: #608ab0;
      width: 100%;
      border-radius: 20px;
      top: 0;
      position: absolute;
      animation: loading 2s ease-in-out infinite alternate;
    }
    width: 60%;
    position: absolute;
    border-radius: 20px;
    background: #a0cef7;
    left: 20%;
    overflow: hidden;
    height: 4%;
    min-height: 2px;
  }

  &__line:nth-child(2) {
    top: 43%;
  }

  &__line:nth-child(3) {
    top: 56%;
    &::after {
      animation-delay: .2s;
    }
  }

  &__line:nth-child(4) {
    top: 69%;
    &::after {
      animation-delay: .4s;
    }
  }
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 220%;
  }
}
