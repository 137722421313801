@use "sass:math";
@import "../../all-modules.global";

.document-entity {
  $progress-bar-height: 4px;
  $checkbox-width: 20px;
  padding: 12px 0 0;
  display: flex;
  cursor: default;
  flex-wrap: wrap;
  position: relative;

  & &__checkbox-loader {
    margin: -4px;
    align-items: flex-start;

    & svg path, & svg rect {
      fill: var(--grey);
    }
  }

  :global(.theme--dark) & {
    &__type-name {
      color: #1e2023;
    }

    &__description {
      color: #a4a7ab;
    }
  }

  $action-width: 34px;
  &__triplet {
    margin: math.div($action-width - $triplet-width, 2) -16px 0 0;
    padding: 0 16px;
  }

  &__description {
    color: #61655c;
    white-space: nowrap;
    @include font-size('xs');
  }

  &__description_progress {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: -2px 0 (-$progress-bar-height) 0;
    color: var(--color-2);
  }

  &__description_loading {
    @include animated-background;
    border-radius: 6px;
    width: 185px;
    margin: 3px 0;
    height: 12px;
    max-width: 60%;
  }

  $type-width: 26px;
  & &__type {
    position: relative;
    padding: 0;
    margin: 0;
    width: 26px;
    height: 34px;

    :global(.st0) {
      fill: var(--light-blue);
    }
    :global(.st1) {
      fill: var(--light-blue-hover);
    }
  }

  &__type_loader {
    width: 32px;
    margin: 0 -4px;
  }

  &__type_doc-loader {
    mask-image: url(/static/img/document-icon.svg);
    @include animated-background;
  }

  $scan-width: 32px;
  &__type_scan {
    &:after {
      content: '';
      display: block;
      left: math.div(($type-width - $scan-width), 2);
      width: $scan-width;
      height: 2px;
      border-radius: 1px;
      background: #00ffc7;
      position: absolute;
      top: 20px;
      box-shadow: 0 0 1px #00d09c;
      @include scan-converting;
    }
    :global(.st0) {
      fill: var(--light-blue);
    }
    :global(.st1) {
      fill: var(--light-blue-hover);
    }
  }

  &__type_loading {
    :global(.st0) {
      fill: var(--icon);
    }
    :global(.st1) {
      fill: var(--icon-hover);
    }
  }

  &__type-name {
    position: absolute;
    font-size: 10px;
    top: 10px;
    left: 5px;
    color: var(--color);
    font-weight: 500;
  }

  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__info {
    @include font-size(s);
    margin: 0 16px;
    width: calc(100% - $type-width - $triplet-width - 16px*2);
    position: relative;
    color: var(--color);
    justify-self: center;
    display: flex;
    flex-direction: column;
    gap: 2px;
    &::after {
      content: '';
      width: 200px;
      height: 1px;
      background: var(--icon);
      margin-top: 12px;
      display: block;
    }

    &_checkbox {
      width: calc(100% - $type-width - $checkbox-width - 16px*2);
    }
  }

  &__title_loading {
    @include animated-background;
    height: 14px;
    margin: 5px 0;
    max-width: 260px;
    width: 100%;
    border-radius: 7px;
  }

  li#{&}__popup-delete {
    background-color: rgba(var(--red-rgb), 0.1);
    color: var(--red);
    &::before {
      mask-image: url(/static/img/trash.svg);
      background-color: var(--red);
    }
    &:hover {
      background-color: rgba(var(--red-rgb), 0.2);
    }
  }

  &__popup-download {
    &::before {
      mask-image: url(/static/img/download.svg);
    }
  }

  &__popup-info {
    &::before {
      mask-image: url(/static/img/info.svg);
    }
  }
}

@media (max-width: 400px) {
  .document-entity {
    &__title {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      line-height: 1.3;
    }
  }
}
