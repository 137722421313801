.success-scan-modal {
  &__list {
    list-style-position: inside;
    li {
      padding: 5px 0;list-style: unset;
    }
    li:last-child {
      margin-bottom: 5px;
    }
    li:first-child {
      margin-top: 5px;
    }
    &_loading {
      pointer-events: none;
      opacity: 0.8;
    }
  }

  & &__loader {
    margin-left: 0;
  }
}
