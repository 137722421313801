@use 'sass:math';
@use 'sass:list';

/*** animated-background ***/
@mixin animated-background($border-radius: 4px) {
  animation: animated-background-shimmer linear 1s infinite forwards;
  background: var(--background-2) linear-gradient(
                  90deg,
                  var(--icon) 8%,
                  var(--icon-hover) 30%,
                  var(--icon) 52%
  );
  border-radius: $border-radius;
  background-size: 200% 100%; /* Double the width to allow sliding effect */

  &:empty:before {
    content: "\200b"; // unicode zero width space character
    color: transparent;
  }

  @keyframes animated-background-shimmer {
    0% {
      background-position: 100% 0
    }
    100% {
      background-position: -100% 0
    }
  }
}

@mixin scan-converting {
  animation: document-scan-icon-converting 2s linear infinite;
  @keyframes document-scan-icon-converting {
    0% {
      top: 50%;
    }
    25% {
      top: 70%;
    }
    75% {
      top: 30%;
    }
    99% {
      top: 50%;
    }
  }
}

@mixin loading-button {
  cursor: progress;
  background-image: repeating-linear-gradient(45deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.1) 10px,
          rgba(0, 0, 0, 0.1) 10px,
          rgba(0, 0, 0, 0.1) 20px
  );
  animation: loading-b 1.5s linear infinite;
  background-position-x: 15%;
  background-size: 165%;
  @keyframes loading-b {
    0% {
      background-position-x: 15%;
    }
    100% {
      background-position-x: 75%;
    }
  }
}

@mixin position-child($n, $x_count: 3) {
  $y_count: math.ceil(math.div($n, $x_count));
  $x_gap: math.div(100%, $x_count - 1);
  $y_gap: math.div(100%, $y_count - 1);
  $delta: math.div(35%, $x_count - 1);

  @for $i from 1 through $n {
    $odd: $i % 2;
    &:nth-child(#{$i}) {
      left: ($i % $x_count) * $x_gap - $delta * $odd;
      top: ($i % $y_count) * $y_gap - $delta * $odd;
    }
  }
}

@function r($d) {
  @return math.random(math.div(2 * $d, 1px)) * 1px - $d;
}

$default_colors: #f1e6bb, #fce876, #b6f6e9, #a5d2fa, #eaa5fa;
@mixin color-animation($total-children, $colors: $default_colors) {
  @for $i from 1 through $total-children {
    &:nth-child(#{$i}) {
      $delay: random(3);
      animation: color-sparkling-#{$i} 6s linear infinite;
    }
    @keyframes color-sparkling-#{$i} {
      $random: random(2) + 0.8;
      $color_i: random(length($colors));
      $r_x: r(10px);
      $r_y: r(10px);
      0% {
        background-color: list.nth($colors, $color_i);
        transform: scale($random) translate($r_x, $r_y);
      }
      30% {
        background-color: list.nth($colors, random(length($colors)));
        transform: scale($random + 1) translate(r(10px), r(10px));
      }
      50% {
        background-color: list.nth($colors, random(length($colors)));
        transform: scale($random + 2) translate(r(10px), r(10px));
      }
      70% {
        background-color: list.nth($colors, random(length($colors)));
        transform: scale($random + 1) translate(r(10px), r(10px));
      }
      100% {
        background-color: list.nth($colors, $color_i);
        transform: scale($random) translate($r_x, $r_y);
      }
    }
  }
}
