.loader {
  text-align: center;
  vertical-align: top;
  display: flex;
  justify-content: center;
  align-items: center;

  svg path, svg rect {
    fill: var(--blue);
  }
}
