@import "../../all-modules.global";

.map-column {
  flex-grow: 1;
  border-radius: 12px;
  position: sticky;
  max-height: calc(100vh - 64px);
  top: 64px;

  & &__map-button {
    display: none;
    $icon-size: 28px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    height: 52px;
    left: 0;
    right: 0;
    width: 208px;
    z-index: 1;
    color: #2e3033;
    @include font-size(m);
    padding: 10px 24px 10px 24px + $icon-size + 4px;
    text-decoration: none;
    background-color: var(--light-blue);
    border-radius: 4px;
    border: 2px solid var(--light-blue);
    transition: background-color .3s, border .3s;


    &:hover {
      text-decoration: none;
      background-color: var(--light-blue-hover);
      border-color: var(--light-blue-hover);
      color: #2e3033;
    }

    &:before {
      position: absolute;
      content: '';
      width: $icon-size;
      height: $icon-size;
      mask-image: url(/static/img/geo.svg);
      background-color: #2e3033;
      left: 24px;
    }
  }
  // width when map = 300px
  @media (max-width: 1044px) {
    &__map {
      display: none;
    }
  }
  // width when map = 500px
  @media (max-width: 1244px) {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgb(42 60 71 / 30%);
      z-index: 1;
      max-height: calc(100vh - 64px);
    }

    & &__map-button {
      display: block;
    }
  }

  &__hint {
    max-width: 12rem;
    height: auto;
    top: -3rem;
    left: -1.5rem;
    font-weight: 400;
    transition: opacity .5s ease;
    opacity: 0;
  }

  &__hint_showed {
    opacity: 1;
    animation: scale .8s ease 1s 2;
  }

  @media (max-width: $mobile-width) {
    &__hint_showe {
      opacity: 0;
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
}