@import "../../all-modules.global";

.document-scan-icon {
  position: relative;
  &:after {
    content: '';
    display: block;
    left: 5%;
    width: 90%;
    height: 2px;
    border-radius: 1px;
    background: #00ffc7;
    position: absolute;
    top: 50%;
    box-shadow: 0 0 1px #00d09c;
    @include scan-converting;
  }

  :global(.st0){
    fill: var(--light-blue);
  }
  :global(.st1) {
    fill: var(--light-blue-hover);
  }
}

