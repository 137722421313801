.progress-bar {
  height: 4px;
  border-radius: 2px;
  background-color: var(--icon);
  position: relative;

  &__bar {
    height: 4px;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 0;
    transition: width .1s linear;
    background: linear-gradient(90deg, #5CB76B 25%, #B6DCFE 75%);
    background-size: 200%;
    animation: bar-background-animation 4s linear infinite;

    @keyframes bar-background-animation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
}