@use "sass:math";
@import "../../all-modules.global";

.ellipsis {
  gap: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-end;
  width: 4px;

  &_disabled {
    pointer-events: none;
  }

  &_loading {
    cursor: not-allowed;
  }

  &__item {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--color);
  }

  &_loading &__item {
    @include animated-background;
  }
}
