@import "../../all-modules.global";

.documents {
  display: flex;
  flex-direction: column;
  $gap: 4px;
  gap: $gap;

  & &__next {
    min-width: 130px;
  }

  &__container {
    max-width: 100%;
  }

  & &__bomb-button {
    margin: 12px auto 0;
  }

  &__select-all-checkbox {
    margin-left: auto;
  }

  &__buttons {
    gap: 16px;
    display: flex;
    margin-top: 8px;
    align-items: center;

    &_disabled button {
      pointer-events: none;
      opacity: $disable-opacity;
    }

    @media (max-width: 360px) {
      gap: 8px;
    }

    button {
      margin: 0;
    }

    @media (max-width: $mobile-width) {
      button {
        padding-left: 12px;
        padding-right: 12px;
      }
      button:first-child {
        flex-grow: 1;
      }
    }
  }

  &__buttons &__button-delete {
    position: relative;
    background: transparent;
    color: var(--red);
    padding: 9px 16px 9px 18px + 8px + 16px;
    border-width: 1px;
    &::before {
      position: absolute;
      transition: .3s linear;
      content: '';
      mask: url(/static/img/trash.svg);
      background-color: var(--red);
      width: 18px;
      height: 18px;
      left: 16px;
      top: 12px;
    }
    &:hover {
      background-color: var(--red);
      color: #fff;

      &::before {
        background-color: #fff;
      }
    }
  }

  &__file-button {
    margin-bottom: 16px - $gap;
  }

  &__drag-and-drop {
    margin: (16px - $gap) 0 16px 0;
  }

  &__title {
    @include font-size(l);
    text-align: left;
    width: 100%;
  }
}

