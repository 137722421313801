@import "../../all-modules.global";

.text-loader {
  @include animated-background;
  border-radius: 1em;

  & {
    margin: 10px 0;
  }

  &:nth-child(2) {
    width: 80%;
  }

  &:nth-child(3), &:nth-child(1) {
    width: 60%;
  }
}
