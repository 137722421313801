@import "../../all-modules.global";

.current-operations {
  width: 50px;
  background: var(--navbar);
  @include shadow;
  border-radius: 4px;
  margin: 40px;
  padding: 8px 4px;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1020;

  &__operation {
    padding: 2px 7px;
    cursor: pointer;
    margin-bottom: 8px;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    &::after {
      background: #0cfec7;
      height: 40px;
      position: absolute;
      top: 0;
      align-items: center;
      opacity: 0;
      transition: .3s;
      content: '';
      left: 0;
      width: 40px;
      padding: 4px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__operation:hover{
    &::before {
      content: 'Открыть';
      z-index: 2;
      display: flex;
      align-items: center;
      font-size: 8px;
      position: absolute;
      height: 40px;
      top: 0;
      left: 0;
      width: 40px;
      padding: 4px;
      color: var(--color);
    }
    &::after {
      opacity: .8;
    }
  }

  &__scan-icon {
    margin: auto;
    &:after {
      content: "";
      display: block;
      left: 4px;
      width: 32px;
      height: 2px;
      border-radius: 1px;
      background: #00ffc7;
      position: absolute;
      top: 20px;
      box-shadow: 0 0 1px #00d09c;
      @include scan-converting;
    }
  }

  &__check {
    position: absolute;
    fill: #0cfec7;
    left: 15px;
    width: 10px;
    top: 17px;
  }

  :global(.st0) {
    fill: var(--light-blue);
  }
  :global(.st1) {
    fill: var(--light-blue-hover);
  }

  div#{&}__loader {
    padding: 0;
    width: 32px;
    margin: 0 -4px;
    height: 32px;
  }
}
