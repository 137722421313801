@import "../../all-modules.global";

.default-point {
  margin: 0 0 24px 0;
  transition: background-color .3s linear;

  &__price {
    font-weight: 500;
  }

  & &__description {
    font-weight: 400;
  }

  &__name-address {
    display: flex;
    flex-wrap: wrap;
    gap: 0 4px;
  }

  &__address {
    display: inline-block;
  }

  &__name {
    font-weight: 500;
    display: inline-block;
  }

  &__link {
    margin-top: 8px;
  }

  @media (max-width: $mobile-width) {
    &__address {
      display: block;
    }
  }
}
