.qr-code-reader {
  height: 280px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 14px;
  position: relative;

  &__qr-corner {
    position: absolute;
    background-image: url('/static/img/qr-corner.svg');
    width: 42px;
    height: 42px;

    &_top {
      top: 0;
    }

    &_bottom {
      bottom: 0;
      transform: rotate(-90deg);
    }

    &_left {
      left: 0;
    }

    &_right {
      right: 0;
      transform: rotate(90deg);
    }

    &_bottom#{&}_right {
      transform: rotate(180deg);
    }
  }

  &__reader {
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}