@import "../../all-modules.global";

.first-page {
  &__next-to-home {
    margin-bottom: 16px;
  }

  &__description {
    margin: 16px 0 24px 0;
    display: flex;
    gap: 4px;
    flex-direction: column;
  }

  &__point {
    margin-top: 16px;
  }

  &__instruction {
    margin-bottom: 36px;
  }

  @media (max-width: 616px) {
    &__instruction {
      margin-bottom: 34px;
    }
  }

  @media (max-width: $mobile-width) {
    margin: 0 0 32px 0;

    &__documents {
      margin-bottom: 64px;
    }

    &__container_point &__heading {
      margin-bottom: 16px;
    }

    &__container_point &__next-to-home {
      display: none;
    }

    &__description {
      margin-top: 8px;
    }
  }
}
