.several-page-modal {
  text-align: left;
  & &__bomb-img {
    width: 186px;
    left: -9px;
    position: relative;
    top: 10px;
    margin-right: -4px;
  }

  &__options-button {
    margin-top: 8px;
  }

  &__options {
    margin: 16px 0 0 0;
    display: flex;

    div {
      margin-bottom: 4px;
    }
  }

  &__options-right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 350px) {
    &__bomb-img {
      display: none;
    }
  }
}
