@use "sass:math";
@import "../../all-modules.global";

.mobile-qr-block {
  $size: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 8px;
  transition: .5s ease-out;
  color: white;
  transform: translateY(300px);
  opacity: 0;
  text-align: center;
  position: absolute;
  top: var(--content-t-padding);
  pointer-events: none;
  max-height: 600px;
  background: transparent !important;

  &_open {
    transform: translateY(0px);
    opacity: 1;
    pointer-events: auto;
  }

  &__qr-reader {
    $qr-reader-size: 260px;
    $margin-y: 24px - 8px;
    border-radius: 4px;
    overflow: hidden;
    margin: $margin-y 0 $margin-y 0;
    height: $qr-reader-size;
    min-height: $qr-reader-size;
    max-height: $qr-reader-size;
    width: $qr-reader-size;
    min-width: $qr-reader-size;
    max-width: $qr-reader-size;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__button {
    width: 100%;
    margin: 0;
    min-height: 52px;
  }

  &__button_type_digital {
    margin-top: auto;
  }
  
  @media (max-width: $mobile-width) {
    max-height: initial;
  }

  &__qr {
    background: #1e2022;
    opacity: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: $header-height;
    bottom: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .3s linear;
    z-index: 10000;
    overflow: scroll;
  }

  &__qr_open {
    opacity: 1;
    pointer-events: auto;
  }
}