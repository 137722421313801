.file-drag-and-drop {
  label {
    padding: 42px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    //border: 2px dashed var(--grey);
    text-align: center;
    cursor: copy;
    background:
            linear-gradient(to right, var(--grey) 50%, var(--background) 50%) -16px 0px repeat-x,
            linear-gradient(to bottom, var(--grey) 50%, var(--background) 50%) 0 -16px repeat-y,
            linear-gradient(to left, var(--grey) 50%, var(--background) 50%) -8px 100% repeat-x,
            linear-gradient(to top, var(--grey) 50%, var(--background) 50%) 100% -8px repeat-y;

    background-size: 16px 2px, 2px 16px, 16px 2px, 2px 16px;
  }

  input {
    display: none;
  }
}