.error-icon {
  position: relative;
  &::before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 2px solid var(--red);
    position: absolute;
    top: 8px;
  }
  &::after {
    content: '';
    width: 2px;
    height: 22px;
    border-radius: 2px;
    position: absolute;
    transform: rotate(45deg);
    background: var(--red);
    left: 10px;
    top: 9px;
  }
}
