@import "../../all-modules.global";

.map {
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    transition: 0.5s;
    position: relative;

    &__container {
        position: relative;
    }

    ymaps[title="Фильтры"] {
        height: 52px;
        border-radius: 8px;
        max-width: 134px !important;
        width: 134px;
        padding-left: 42px;
        top: 2px;
    }

    ymaps[title="Фильтры"] :global(.ymaps-2-1-79-float-button-icon) {
        margin-left: 16px;
        border: 0;
    }

    ymaps[title="Фильтры"] :global(.ymaps-2-1-79-float-button-text) {
        font-size: var(--font-size-sm);
        line-height: var(--line-height-sm);
    }

    ymaps[title="Найти"] {
        top: 2px;
    }

    &__scan-qr-icon {
        color: transparent;
        position: absolute;
        width: 52px;
        height: 52px;
        overflow: hidden;
        border: 2px solid #E3E9EC;
        border-radius: 8px;
        top: 198px + 64px;
        right: 12px;
        min-width: 52px;
        background: #fff;
        &::after {
            position: absolute;
            content: "";
            width: 14px;
            height: 14px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background: url(/static/img/qr-code.svg) no-repeat;
        }
    }

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        pointer-events: none;
        transition: .4s;
    }

    &_loading::after {
        background: #fcfcf8;
    }

    :global(.ymaps-2-1-79-float-button) {
        height: 52px;
        width: 52px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 2px solid #E3E9EC;
        background-color: #fff;
        box-shadow: none;
        transition: linear .15s;
    }

    :global(.ymaps-2-1-79-float-button):hover {
        background-color: var(--icon);
    }

    :global(.ymaps-2-1-79-float-button-icon_icon_geolocation) {
        right: 0;
        background-image: url('/static/img/geolocation.svg');
        margin: auto;
    }

    :global(.ymaps-2-1-79-float-button-icon_icon_geolocation):hover {
        background-color: var(--icon);
    }

    :global(.ymaps-2-1-79-zoom__plus) {
        border-radius: 8px 8px 0 0;
        border-bottom: none;
        transition: linear .15s;
    }

    :global(.ymaps-2-1-79-zoom__plus):hover {
        background-color: var(--icon);
    }

    :global(.ymaps-2-1-79-zoom__minus)::before {
        content: '';
        width: 26px;
        left: 0;
        right: 0;
        top: -1px;
        position: absolute;
        height: 2px;
        background-color: var(--icon);
        border-radius: 2px;
        margin: auto;
    }

    :global(.ymaps-2-1-79-zoom__plus) :global(.ymaps-2-1-79-zoom__icon) {
        background-image: url('/static/img/plus.svg');
        right: 0;
        margin: auto;
    }

    :global(.ymaps-2-1-79-zoom__minus) {
        top: 50px;
        border-radius: 0 0 8px 8px;
        border-top: none;
        transition: linear .15s;
    }

    :global(.ymaps-2-1-79-zoom) {
        left: -24px;
    }

    :global(.ymaps-2-1-79-zoom__minus) :global(.ymaps-2-1-79-zoom__icon) {
        background-image: url('/static/img/minus.svg');
        right: 0;
        margin: auto;
    }

    :global(.ymaps-2-1-79-searchbox) :global(.ymaps-2-1-79-float-button) {
        min-width: 120px;
        padding: 0 12px;
    }
    :global(.ymaps-2-1-79-searchbox) :global(.ymaps-2-1-79-float-button-text) {
        @include font-size(m);
        width: 100%;
        text-align: center;
    }

    :global(.ymaps-2-1-79-search_layout_normal) {
        max-width: calc(100% - 52px - 16px);
        top: 2px
    }

    :global(.ymaps-2-1-79-gototech),
    :global(ymaps-2-1-79-copyright__link),
    :global(.ymaps-2-1-79-gotoymaps),
    :global(.ymaps-2-1-79-copyright),
    :global(.ymaps-2-1-79-balloon__layout)
    {
        display: none;
    }
}
